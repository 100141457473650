<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 one-click-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="one-click-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_one-click' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("one-click", "find-person", "find-person") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul
      class="clebex-item-section pill"
      v-if="findPersonDeclarations && findPersonDeclarations.length"
    >
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          <div class="label">
            {{ displayLabelName("one-click", "find-person", "declarations") }}
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="declaration in findPersonDeclarations"
        :key="declaration.id"
      >
        <button
          @click="toMap(declaration)"
          class="clebex-item-content-wrapper"
          :class="{ 'cursor-default': !declaration.level.map_id }"
        >
          <span class="label find-person-list">
            <div class="clebex-widget-plan-top">
              {{ declaration.resource.name
              }}{{
                declaration.resource.path && declaration.resource.path.length
                  ? ", "
                  : ""
              }}
              <span
                v-if="
                  declaration.resource.path && declaration.resource.path.length
                "
              >
                {{ declaration.resource.path.join(", ") }}
              </span>
            </div>
            <div class="clebex-widget-plan-bottom">
              {{ getTime(declaration.datetime_from, true) }} |
              {{ getTime(declaration.datetime_from) }} -
              {{ getTime(declaration.datetime_to) }}
            </div>
          </span>
          <span v-if="declaration.level.map_id" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <ul
      class="clebex-item-section pill"
      v-if="findPersonAssignments && findPersonAssignments.length"
    >
      <li class="clebex-item-section-label-inner">
        <div class="clebex-label-content-wrapper">
          <div class="label">
            {{
              displayLabelName(
                "one-click",
                "find-person",
                "resource-assignments"
              )
            }}
          </div>
        </div>
      </li>
      <li
        class="clebex-item-section-item full-right-underline"
        v-for="assignment in findPersonAssignments"
        :key="assignment.id"
      >
        <button
          @click="toMap(assignment, true)"
          class="clebex-item-content-wrapper"
          :class="{ 'cursor-default': !assignment.level.map_id }"
        >
          <span class="label find-person-list">
            <div class="clebex-widget-plan-top">
              {{ assignment.resource.name
              }}{{
                assignment.resource.path && assignment.resource.path.length
                  ? ", "
                  : ""
              }}
              <span
                v-if="
                  assignment.resource.path && assignment.resource.path.length
                "
              >
                {{ assignment.resource.path.join(", ") }}
              </span>
            </div>
            <div
              class="clebex-widget-plan-bottom"
              :class="{ 'visibility-hidden': !assignment.original_datetime_to }"
            >
              {{
                assignment.original_datetime_to
                  ? getTime(assignment.original_datetime_to, true)
                  : "0"
              }}
            </div>
          </span>
          <span v-if="assignment.level.map_id" class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapGetters } from "vuex";
import { getLang, getSubdomain } from "@/services/http-service";
import dayjs from "dayjs";
import qs from "qs";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "OneClickFindPerson",
  mixins: [mapMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    if (!this.findPersonDeclarations && !this.findPersonAssignments) {
      this.$router.push({
        name: "r_one-click"
      });
    }
  },
  computed: {
    ...mapState("oneClick", [
      "findPersonDeclarations",
      "findPersonAssignments"
    ]),
    ...mapGetters("settings", ["globalDateFormat"])
  },
  methods: {
    toMap(declaration, isAssignment = false) {
      this.$store.commit("oneClick/setFindPersonUrl", null, {
        root: true
      });
      this.$store.commit("oneClick/setFindPersonMapPath", null, {
        root: true
      });
      if (!declaration.level.map_id) {
        return;
      }

      let path = null;
      if (declaration.resource.path && declaration.resource.path.length) {
        path = declaration.resource.path.join(", ");
      }
      this.$store.commit("oneClick/setFindPersonMapPath", path, {
        root: true
      });

      const subdomain = getSubdomain();
      let subdomainUrl = "";
      if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;

      const slotsUrl = qs.stringify({
        type: "QUARTER_HOUR",
        slots: {
          from: declaration.datetime_from,
          to: declaration.datetime_to
        }
      });

      const person = `&person=${declaration.user_id}`;

      const declarationId = `&${isAssignment ? "assignment" : "declaration"}=${
        declaration.id
      }`;

      const url = `${process.env.VUE_APP_5DMAPS_URL}/view/${
        declaration.level.map_id
      }?access_token=${localStorage.getItem(
        "atApp"
      )}&language=${getLang()}&mode=plan${subdomainUrl}&${slotsUrl}${person}${declarationId}`;
      this.$store.commit("oneClick/setFindPersonUrl", url, {
        root: true
      });
      this.$router.push({
        name: "r_one-click-find-person-map",
        params: {
          backLinkName: "r_one-click-find-person"
        }
      });
    },
    getTime(date, day = false) {
      if (day) {
        return dayjs(date).format(this.globalDateFormat || "DD MMMM YYYY");
      }
      return dayjs(date).format("HH:mm");
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        if (
          (val.name === "r_one-click-find-person" &&
            !this.findPersonDeclarations &&
            !this.findPersonAssignments) ||
          (this.findPersonDeclarations &&
            !this.findPersonDeclarations.length &&
            this.findPersonAssignments &&
            !this.findPersonAssignments.length)
        ) {
          this.$router.push({
            name: "r_one-click"
          });
        }
      }
    }
  }
};
</script>
